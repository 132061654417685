
import ApiService from "@/core/services/ApiService"
import { useRoute, useRouter } from "vue-router"
import { computed, defineComponent, onMounted, ref, watch } from "vue"
import { Edit } from "@element-plus/icons-vue"
import CorporationComponent from "@/components/general/definitions/corporation/corporation.vue"
import { Corporation } from "@/core/models/Corporation"
import swalConfirm from "@/core/helpers/swal-confirm"
export default defineComponent({
  name: "corporation-list",
  components: {
    CorporationComponent,
  },
  props: ["add"],
  setup(props) {
    const route = useRoute()
    const router = useRouter()
    const corporationList = ref<Corporation[]>([])
    const filterOnlyActives = ref(true)
    const isLoading = ref(true)
    const newCorporation = ref(false)
    const activeCorporationId = ref<string | undefined>()

    watch(
      () => route.params.corporation_id,
      newValue => {
        activeCorporationId.value = newValue as string
      }
    )

    watch(
      () => props.add,
      newValue => {
        newCorporation.value = true == newValue
      }
    )

    const corporationListFiltered = computed(() => {
      return corporationList.value.filter(x => {
        return x["isActive"] == true || !filterOnlyActives.value
      })
    })

    const getCorporationList = async () => {
      isLoading.value = true
      const { data } = await ApiService.get("corporation")
      corporationList.value = data
      isLoading.value = false
    }

    const removeCorporation = async corporationId => {
      const confirmResult = await swalConfirm()
      if (confirmResult.isConfirmed) {
        await ApiService.get("corporation/remove/" + corporationId)
        await getCorporationList()
      }
    }

    const handleCorporationDrawerClose = () => {
      router.push({ name: "corporation-list" })
    }

    onMounted(async () => {
      activeCorporationId.value = route.params.corporation_id as string
      newCorporation.value = props.add
      await getCorporationList()
    })

    return {
      corporationList,
      Edit,
      filterOnlyActives,
      corporationListFiltered,
      isLoading,
      removeCorporation,
      getCorporationList,
      newCorporation,
      activeCorporationId,
      handleCorporationDrawerClose,
    }
  },
})
