import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"flex":"auto"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_drawer = _resolveComponent("el-drawer")!

  return (_openBlock(), _createBlock(_component_el_drawer, {
    size: "50%",
    modelValue: _ctx.showDrawer,
    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.showDrawer) = $event)),
    onClosed: _cache[10] || (_cache[10] = ($event: any) => (_ctx.$emit('closed')))
  }, {
    header: _withCtx(() => [
      _createElementVNode("h4", null, "Şirket " + _toDisplayString(_ctx.newCorporation ? "Ekle" : "Düzenle"), 1)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        ref: "ruleFormRef",
        model: _ctx.corporationData,
        "label-width": "150px",
        "label-position": "right",
        rules: _ctx.rules
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            label: "Şirket Adı",
            prop: "title"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.corporationData.title,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.corporationData.title) = $event)),
                placeholder: "Şirket Adı Giriniz"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            label: "Kısa Ad",
            prop: "shortName"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.corporationData.shortName,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.corporationData.shortName) = $event)),
                placeholder: "Kısa Ad Giriniz"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            label: "Resmi Ünvan",
            prop: "officialTitle"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.corporationData.officialTitle,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.corporationData.officialTitle) = $event)),
                placeholder: "Resmi Ünvan Giriniz"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            label: "Vergi Numarası",
            prop: "taxNumber"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.corporationData.taxNumber,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.corporationData.taxNumber) = $event)),
                modelModifiers: { number: true },
                placeholder: "Vergi No Giriniz"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            label: "Vergi Dairesi",
            prop: "taxDepartment"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.corporationData.taxDepartment,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.corporationData.taxDepartment) = $event)),
                placeholder: "Vergi Dairesi Giriniz"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            label: "Ticaret Sicil Numarası",
            prop: "tradeRegistryNumber"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.corporationData.tradeRegistryNumber,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.corporationData.tradeRegistryNumber) = $event)),
                placeholder: "Ticaret Sicil No Giriniz"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            label: "Mersis Numarası",
            prop: "mersisNumber"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.corporationData.mersisNumber,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.corporationData.mersisNumber) = $event)),
                maxlength: "16",
                placeholder: "1234-5678-9010-0012"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            label: "Aktif",
            prop: "isActive"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_switch, {
                modelValue: _ctx.corporationData.isActive,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.corporationData.isActive) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.addOrUpdate(_ctx.ruleFormRef)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.newCorporation ? "Ekle" : "Düzenle"), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}